<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 5C5.44 5 3.65 9.31 6.17 11.83C8.69 14.35 13 12.56 13 9C13 6.79 11.21 5 9 5ZM2 12H0V16C0 17.1 0.9 18 2 18H6V16H2V12ZM2 2H6V0H2C0.9 0 0 0.9 0 2V6H2V2ZM16 0H12V2H16V6H18V2C18 0.9 17.1 0 16 0ZM16 16H12V18H16C17.1 18 18 17.1 18 16V12H16" fill="#7CB858"/>
  </svg>

</template>

<script>
  export default {
  name: "QrCodeSvg"
}
</script>

<style lang="scss" scoped>

</style>