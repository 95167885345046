<template lang="pug">
  .home
    .container-fluid
      TopBar.app-padding
    .container-fluid.app-padding.app-container(
      v-if="getCurrentTab==='serversAll' || getCurrentTab ==='serversMy'"
    )
      .row
        .col.s5
          ServerSelectForm
      .row
        .col.s5
          AppServers
        .col.s7
          ServerDescription(
            v-if="isAnyActive"
            :_id="getActiveServer._id"
            :name="getActiveServer.name"
            :onlineLimit="getActiveServer.onlineLimit"
            :onlineCurrent="getActiveServer.onlineCurrent",
            :isForge="getActiveServer.isForge",
            :isOnline="getActiveServer.isOnline",
            :minecraftVersion="getActiveServer.minecraftVersion",
            :serverAddress="getActiveServer.serverAddress",
            :previewImageUrl="getActiveServer.previewImageUrl",
            :coverImageUrl="getActiveServer.coverImageUrl",
            :description="getActiveServer.description",
            :updatedAt="getActiveServer.updatedAt"
            :tags="getActiveServer.tags"
            :modList="getActiveServer.modList",
            :playerList="getActiveServer.playerList"
          )
    .container-fluid.app-padding.app-container(
      v-if="getCurrentTab==='settings'"
    )
      .row.settings-row
        .col.s12
          AppSettings
          form(
            @submit="formSignUp"
            v-if="!isLoggedIn"
          )
            h2 Register
            input(
              name="name",
              type="text",
              ref="reg_name"
            )
            input(
              name="email",
              type="email",
              ref="reg_email"
            )
            input(
              name="password",
              type="password",
              ref="reg_pass"
            )
            button(type="submit") Регистрация
        .col.s12
          form(
            @submit="formSignIn"
            v-if="!isLoggedIn"
          )
            h2 SignIn
            input(
              name="name",
              type="text",
              ref="auth_name"
            )
            input(
              name="password",
              type="password",
              ref="auth_pass"
            )
            button(type="submit") Вход
        .col.s12
          button.logout(
            v-if="isLoggedIn"
            @click="logout"
          ) Выход
</template>

<script>
// @ is an alias to /src

import TopBar from "@/components/TopBar";
import ServerSelectForm from "@/components/ServerSelectForm";
import AppServers from "@/components/AppServers";
import ServerDescription from "@/components/ServerDescription";
import AppSettings from "@/components/AppSettings";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    AppSettings,
    ServerDescription,
    AppServers,
    ServerSelectForm,
    TopBar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("User", ["isLoggedIn"]),
    ...mapGetters("Tab", ["getCurrentTab"]),
    ...mapGetters("Platforms", ["getActiveServer", "isAnyActive"]),
  },
  methods: {
    ...mapActions("User", ["signUp", "signIn", "logout"]),
    formSignUp(e) {
      e.preventDefault();
      let username = this.$refs.reg_name.value;
      let password = this.$refs.reg_pass.value;
      let email = this.$refs.reg_email.value;
      this.signUp({ username, email, password });
    },
    formSignIn(e) {
      e.preventDefault();
      let username = this.$refs.auth_name.value;
      let password = this.$refs.auth_pass.value;
      this.signIn({ username, password });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-row {
  margin-top: 60px;
}
</style>
