<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM12.1 18.7H9.9V16.5H12.1V18.7ZM14.377 10.175L13.387 11.187C12.595 11.99 12.1 12.65 12.1 14.3H9.9V13.75C9.9 12.54 10.395 11.44 11.187 10.637L12.551 9.251C12.958 8.855 13.2 8.305 13.2 7.7C13.2 6.49 12.21 5.5 11 5.5C9.79 5.5 8.8 6.49 8.8 7.7H6.6C6.6 5.269 8.569 3.3 11 3.3C13.431 3.3 15.4 5.269 15.4 7.7C15.4 8.668 15.004 9.548 14.377 10.175Z" fill="#7CB858"/>
  </svg>
</template>

<script>
export default {
  name: 'InfoSvg',
};
</script>

<style lang="scss" scoped>

</style>