import Vue from "vue";
import Vuex from "vuex";
import User from "@/store/modules/User";
import Tab from "@/store/modules/Tab";
import Platforms from "@/store/modules/Platforms";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    User,
    Tab,
    Platforms,
  },
});
