<template >
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 14H12V12.3529H0V14ZM12 4.94118H8.57143V0H3.42857V4.94118H0L6 10.7059L12 4.94118Z" fill="#7CB858"/>
  </svg>
</template>

<script>
  export default {
    name: 'UpdateSvg',
  };
</script>

<style lang="scss" scoped>

</style>