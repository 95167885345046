import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLodash from "vue-lodash";
import { debounce } from "lodash";

Vue.use(VueLodash, { name: "custom", lodash: { debounce } });
Vue.config.productionTip = false;
Vue.use(require("vue-moment"));

Vue.mixin({
  mounted() {
    M.AutoInit();
    this.$nextTick(M.updateTextFields());
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
