<template lang="pug">
  .settings
    .row
      .col.s6
        FileInput.file-input(
          label="Путь хранения данных",
          id="path1"
        )
        FileInput.file-input(
          label="Путь хранения данных",
          id="path2"
        )
        FileInput.file-input(
          label="Путь хранения данных",
          id="path3"
        )
        .input-field
          input(
            :value="8139",
            placeholder="8192",
            id="id"
            type="text"
            class="validate",
            disabled,
          )
          label(for="id") Лимит памяти, МБ
      .col.s6
        .progress-wrap
          .descr
            .name На диске C: свободно 12гб (17%)
            .val 112
          .progress
            .determinate(
              :style="{width: 70 + '%'}"
            )
        .progress-wrap
          .descr На диске C: свободно 12гб (17%)
          .progress
            .determinate(
              :style="{width: 70 + '%'}"
            )
        .progress-wrap
          .descr На диске C: свободно 12гб (17%)
          .progress
            .determinate(
              :style="{width: 70 + '%'}"
            )
</template>

<script>
import FileInput from "@/components/FileInput";
export default {
  name: "AppSettings",
  components: { FileInput },
};
</script>

<style lang="scss" scoped>
.settings {
  max-width: 700px;
  margin: auto;
}
</style>
<style lang="scss">
.settings input[disabled] {
  color: rgba(238, 238, 238, 0.87) !important;
  border-bottom: 2px solid #7cb858 !important;
  box-shadow: 0 1px 0 0 #7cb858;
  & + label {
    color: #7cb858 !important;
  }
}
.file-input {
  margin-bottom: 25px;
}
.progress-wrap {
  margin-top: 1rem;
  margin-bottom: 25px;
  padding-bottom: 1px;
  .progress {
    background-color: rgba(124, 184, 88, 0.24);
    height: 3px;
    margin-top: 0;
    margin-bottom: 6px;
  }
  &:last-child {
    margin-top: auto;
  }
  .descr {
    font-size: 13px;
    height: calc(3rem);
    color: rgba(238, 238, 238, 0.87);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
