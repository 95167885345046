import api from "../api";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    isLoggedIn(state) {
      return state.user !== null;
    },
    getUsername(state) {
      return state.user.username;
    },
  },
  actions: {
    signUp: async function (
      { commit, dispatch },
      { username, password, email }
    ) {
      try {
        let response = await api.post("/auth/sign-up", {
          username,
          password,
          email,
        });
        console.log(response);
        if (response) {
          if (response.data.access_token) {
            commit("signUpSuccess", {
              user: response.data.user,
              accessToken: response.data.access_token,
            });
            dispatch("Platforms/init", null, { root: true });
          }
        } else {
          console.log("error");
        }
      } catch (error) {
        console.dir(error);
      }
    },
    signIn: async function ({ commit, dispatch }, { username, password }) {
      try {
        let response = await api.post("/auth/sign-in", {
          username,
          password,
        });
        if (response) {
          if (response.data.access_token) {
            commit("signInSuccess", {
              user: response.data.user,
              accessToken: response.data.access_token,
            });
            dispatch("Platforms/init", null, { root: true });
          }
        }
      } catch (error) {
        // console.dir(error);
      }
    },
    auth: async function ({ commit, dispatch }) {
      try {
        let response = await api.get("/users/me");
        if (response) {
          commit("authSuccess", { user: response.data });
          // dispatch("Platforms/init", null, { root: true });
        }
      } catch (error) {
        console.dir(error);
      }
    },
    logout: function ({ commit }) {
      commit("logout");
    },
  },
  mutations: {
    signUpSuccess(state, { user, accessToken }) {
      state.user = user;
      localStorage.setItem(process.env.VUE_APP_TOKEN_LOCATION, accessToken);
      api.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
    },
    signInSuccess(state, { user, accessToken }) {
      state.user = user;
      localStorage.setItem(process.env.VUE_APP_TOKEN_LOCATION, accessToken);
      api.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
    },
    authSuccess(state, { user }) {
      state.user = user;
    },
    logout(state) {
      state.user = null;
      localStorage.removeItem(process.env.VUE_APP_TOKEN_LOCATION);
      api.defaults.headers["Authorization"] = null;
    },
  },
};
