<script>
import VueCharts from "vue-chartjs";

export default {
  extends: VueCharts.Line,
  props: ["chartdata", "options", "height"],
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>

<style lang="scss" scoped></style>
