import api from "../api";

export default {
  namespaced: true,
  state: {
    servers: [],
    activeServerId: null,
    nameFilter: null,
  },
  getters: {
    getAllServers(state) {
      let filteredServers = state.servers;
      if (state.nameFilter) {
        filteredServers = filteredServers.filter((e) =>
          e.name.includes(state.nameFilter)
        );
      }
      return filteredServers;
    },
    isAnyActive(state) {
      return state.activeServerId !== null;
    },
    getActiveServer(state) {
      return state.servers.find((e) => e._id === state.activeServerId);
    },
  },
  actions: {
    init: async function ({ commit }) {
      try {
        let response = await api.get("/platforms");
        if (response) {
          commit("initServers", response.data);
        }
      } catch (error) {
        console.dir(error);
      }
    },
    changeActiveServer({ commit }, _id) {
      if (_id) {
        commit("setActiveServerId", _id);
      }
    },
    changeNameFilter({ commit }, name) {
      commit("changeNameFilter", name);
    },
  },

  mutations: {
    initServers(state, servers) {
      state.servers = servers.reverse();
      if (servers.length > 0) {
        state.activeServerId = state.servers[0]._id;
      }
    },
    setActiveServerId(state, _id) {
      state.activeServerId = _id;
    },
    changeNameFilter(state, name) {
      state.nameFilter = name;
    },
  },
};
