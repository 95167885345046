<template lang="pug">
  .topbar
    .row.w100
      .col.s12.app-tabs
        a.tab-nav-link.left-item(
          linkTo="MyServers"
          @click="switchTab('serversMy')"
          :class="{'active-tab': getCurrentTab === 'serversMy'}"
        )
          ServersSvg
          span.descr Мои сервера
        a.tab-nav-link.mr-auto.left-item(
          linkTo="AllServers"
          @click="switchTab('serversAll')"
          :class="{'active-tab': getCurrentTab === 'serversAll'}"
        )
          ServersSvg
          span.descr Доступные сервера
        a.right-item
          QrCodeSvg
        a.right-item
          WarningSvg
        a.right-item
          ResourcesSvg
        a.progress-item
          RadialProgressBar(
            :diameter="46"
            :completedSteps="5"
            :strokeWidth="3"
            :totalSteps="10"
            startColor="#7CB858"
            stopColor="#7CB858"
            innerStrokeColor="transparent"
          )
            p 100%
        a.left-item.progress-item
          RadialProgressBar(
            :diameter="46"
            :completedSteps="8"
            :strokeWidth="3"
            :totalSteps="10"
            startColor="#7CB858"
            stopColor="#7CB858"
            innerStrokeColor="transparent"
          )
            UpdateSvg
        a.right-item(v-if="!isLoggedIn")
          LoginSvg
          span.descr Вход
        a.right-item(v-if="isLoggedIn")
          span.descr {{getUsername}}
        a.tab-nav-link.right-item(
          linkTo="Settings"
          @click="switchTab('settings')"
          :class="{'active-tab': getCurrentTab === 'settings'}"
        )
          OptionsSvg
          span.descr Настройки
        a.right-item()
          InfoSvg
</template>

<script>
import ServersSvg from "@/components/icons/ServersSvg";
import OptionsSvg from "@/components/icons/OptionsSvg";
import LoginSvg from "@/components/icons/LoginSvg";
import QrCodeSvg from "@/components/icons/QRcodeSvg";
import WarningSvg from "@/components/icons/WarningSvg";
import UpdateSvg from "@/components/icons/UpdateSvg";
import ResourcesSvg from "@/components/icons/ResourcesSvg";
import RadialProgressBar from "vue-radial-progress";
import InfoSvg from "@/components/icons/InfoSvg";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TopBar",
  components: {
    InfoSvg,
    ResourcesSvg,
    UpdateSvg,
    WarningSvg,
    QrCodeSvg,
    LoginSvg,
    OptionsSvg,
    ServersSvg,
    RadialProgressBar,
  },
  computed: {
    ...mapGetters("User", ["isLoggedIn", "getUsername"]),
    ...mapGetters("Tab", ["getCurrentTab"]),
  },
  methods: {
    ...mapActions("Tab", ["switchTab"]),
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  background: rgba(21, 22, 24, 0.29);
  height: 60px;
}
.row {
  margin-bottom: 0;
  height: 100%;
}
.app-tabs {
  height: 100%;
  display: flex;
  align-items: center;
  a {
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
    height: 100%;
    transition: all 0.5s ease-in-out;

    .descr {
      margin-left: 10px;
    }
  }
  .tab-nav-link {
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid white;
    }
  }
  .active-tab {
    border-bottom: 2px solid white;
  }
}
.progress-item {
  font-size: 8px;
}
.left-item {
  margin-right: 30px;
}
.right-item {
  margin-left: 20px;
}
</style>
