import axios from "axios";

export default axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(
      process.env.VUE_APP_TOKEN_LOCATION
    )}`,
  },
});
