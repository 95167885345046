<template lang="pug">
  #app
    router-view
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  created() {
    if (localStorage.getItem(process.env.VUE_APP_TOKEN_LOCATION)) {
      this.auth();
      this.init();
    }
  },
  computed: {},
  methods: {
    ...mapActions("User", ["auth"]),
    ...mapActions("Platforms", ["init"]),
  },
};
</script>

<style lang="scss">
@import "assets/fonts/Roboto/Roboto.css";
@import "~reset-css";
@import "assets/scss/colors";
@import "~materialize-css";
@import "assets/scss/App";
</style>
