export default {
  namespaced: true,
  state: {
    tab: "serversMy",
    allTabs: ["serversAll", "serversMy", "settings"],
  },
  getters: {
    getCurrentTab(state) {
      return state.tab;
    },
  },
  actions: {
    switchTab({ commit }, tabName) {
      commit("setCurrentTab", tabName);
    },
  },

  mutations: {
    setCurrentTab(state, tabName) {
      if (state.allTabs.includes(tabName)) {
        state.tab = tabName;
      } else {
        console.log("No no no");
      }
    },
  },
};
