<template lang="pug">
  .server-card
    .img-wrap
      img(
        :src="previewImageUrl"
        width="100"
        height="100"
      )
    .info-wrap
      .left-info
        .name {{name}}
        .online {{onlineCurrent}}/{{onlineLimit}}
        .bottom-wrap
          span.version {{minecraftVersion}},&nbsp;
          span.type(v-if="isForge") Forge,&nbsp;
          span.type(v-if="!isForge") No Forge,&nbsp;
          span.address {{serverAddress}}
      .right-info
        .middle-wrap
          a.play
            PlaySvg
            span.descr Играть
          a.server-settings
            OptionsSvg
        .date {{getDate |  moment("YYYY.MM.DD hh:mm") }}
</template>

<script>
import PlaySvg from "@/components/icons/PlaySvg";
import OptionsSvg from "@/components/icons/OptionsSvg";
export default {
  name: "ServerCard",
  components: { OptionsSvg, PlaySvg },
  props: {
    _id: { type: String, required: true },
    name: { type: String, required: true },
    onlineLimit: { type: Number, required: true },
    onlineCurrent: { type: Number, required: true },
    isForge: { type: Boolean, required: true },
    isOnline: { type: Boolean, required: true },
    minecraftVersion: { type: String, required: true },
    serverAddress: { type: String, required: true },
    previewImageUrl: { type: String, required: true },
    coverImageUrl: { type: String, required: true },
    description: { type: String, required: true },
    updatedAt: { type: String, required: true },
    tags: { type: Array, default: () => [] },
    modList: { type: Array, default: () => [] },
    playerList: { type: Array, default: () => [] },
  },
  computed: {
    getDate() {
      return this.updatedAt;
      // return moment(this.updatedAt).format("YYYY.MM.DD hh:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.server-card {
  color: #eeeeee;
  background-color: #2b2c31;
  display: flex;
  .img-wrap {
    width: 100px;
    img {
      object-fit: cover;
    }
  }
  .info-wrap {
    width: calc(100% - 100px);
    padding: 6px 18px 6px 10px;
    display: flex;
    .left-info {
      display: flex;
      flex-direction: column;
      height: 100%;
      .bottom-wrap {
        font-size: 10px;
        margin-top: auto;
      }
    }
    .name {
      font-size: 18px;
      line-height: 21px;
    }
    .online {
      font-size: 10px;
      margin-top: 3px;
      padding-left: 11px;
      position: relative;
      &::before {
        content: url("~@/assets/img/online.png");
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .right-info {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      .middle-wrap {
        margin-top: auto;
        justify-content: flex-end;
        margin-bottom: 20px;
        display: flex;
        a {
          display: flex;
          margin-left: 20px;
          align-items: center;
          color: #7cb858;
          cursor: pointer;
          .descr {
            margin-left: 5px;
          }
        }
      }
    }
    .date {
      text-align: right;
      font-size: 10px;
      line-height: 12px;
    }
  }
}
</style>
