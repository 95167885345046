<template lang="pug">
  .server-descr-wrap
    .server-descr
      .banner
        img.cover-img(
          :src="coverImageUrl"
          width="100%"
        )
        .server-img
          img(
            :src="previewImageUrl"
            width=100
            height=100
          )
      .row
        .col.s6
          .server-info
            .name {{name}}
            .online {{onlineCurrent}}/{{onlineLimit}}
            .bottom-wrap
              span.version {{minecraftVersion}},&nbsp;
              span.type(v-if="isForge") Forge,&nbsp;
              span.type(v-if="!isForge") No Forge,&nbsp;
              span.address {{serverAddress}}
          .server-tags
            span.tag(
              v-for="item in tags"
            ) {{'#'+item}}
          .chart
            .chart-name Онлайн
            LineChart.chart-graphic(
              :chartdata="chartData"
              :options="chartOptions"
              height="150"
            )
        .col.s6
          .descr-text {{description}}
          .row.addition
            .col.s3
              .mods
                .descr Модификации
                .mod(
                  v-for="item in modList"
                ) {{item}}
            .col.s3.offset-s3
              .users
                .descr Игроки
                .user(
                  v-for="item in playerList"
                )
                  img(
                    src="@/assets/img/Avatar.png"
                    width="15"
                    height="15"
                  )
                  span.username {{item}}
</template>

<script>
import LineChart from "@/components/LineChart";
export default {
  name: "ServerDescription",
  components: { LineChart },
  props: {
    _id: { type: String, required: true },
    name: { type: String, required: true },
    onlineLimit: { type: Number, required: true },
    onlineCurrent: { type: Number, required: true },
    isForge: { type: Boolean, required: true },
    isOnline: { type: Boolean, required: true },
    minecraftVersion: { type: String, required: true },
    serverAddress: { type: String, required: true },
    previewImageUrl: { type: String, required: true },
    coverImageUrl: { type: String, required: true },
    description: { type: String, required: true },
    updatedAt: { type: String, required: true },
    tags: { type: Array, default: () => [] },
    modList: { type: Array, default: () => [] },
    playerList: { type: Array, default: () => [] },
  },
  data() {
    return {
      chartData: {
        labels: [
          "4:00",
          "5:00",
          "6:00",
          "7:00",
          "8:00",
          "9:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
        ],
        borderColor: "#eeeeee",
        datasets: [
          {
            label: "Data One",
            borderColor: "#1A8EF9",
            data: [47, 93, 20, 81, 56, 190, 17, 84, 276, 20],
            fill: false,
          },
        ],
      },
      chartOptions: {
        legend: {
          display: false,
        },
        aspectRatio: 3,
        elements: {
          line: {
            tension: 0,
          },
        },
        maintainAspectRatio: true,
        scales: {
          yAxes: [
            {
              ticks: {
                // min: 0,
                // stepSize: 1,
                maxTicksLimit: 5,
                fontColor: "#eeeeee",
                fontSize: 10,
              },
              gridLines: {
                color: "#eeeeee",
                lineWidth: 2,
                zeroLineColor: "#eeeeee",
                zeroLineWidth: 2,
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#eeeeee",
                fontSize: 10,
              },
              gridLines: {
                color: "#eeeeee",
                lineWidth: 2,
              },
            },
          ],
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.server-descr-wrap {
  max-height: calc(100vh - 190px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 25px;
  max-width: 797px;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #757575;
  }
}
.server-descr {
  color: #eeeeee;
  background-color: #2b2c31;
  height: 100%;
}
.banner {
  position: relative;
  .cover-img {
    object-fit: cover;
    max-height: 200px;
  }
  .server-img {
    position: absolute;
    left: 37px;
    bottom: -55px;
  }
}
.server-info {
  margin-left: 150px;
}
.name {
  font-size: 18px;
  line-height: 21px;
  margin-top: 3px;
}
.online {
  font-size: 10px;
  margin-top: 3px;
  padding-left: 11px;
  position: relative;
  &::before {
    content: url("~@/assets/img/online.png");
    position: absolute;
    left: 0;
    top: 0;
  }
}
.server-img {
  img {
    object-fit: cover;
  }
}
.bottom-wrap {
  font-size: 10px;
  margin-top: 2px;
}
.descr-text {
  margin-top: 9px;
  font-size: 12px;
  line-height: 14px;
}

.server-tags {
  margin-top: 10px;
  color: #7cb858;
  margin-left: 37px;
}

.chart {
  margin-top: 6px;
  margin-left: 37px;
  .chart-name {
    font-size: 10px;
    line-height: 12px;
  }
  .chart-lorem {
    margin-top: 5px;
    max-width: 100%;
    transform: translateX(-30px);
  }
}
.addition {
  margin-top: 17px;
  .descr {
    color: #7cb858;
  }
  .mod,
  .user {
    font-size: 10px;
    line-height: 12px;
  }
  .mod {
    margin: 3px 0;
    margin-left: 2px;
  }
  .user {
    margin: 10px 0;
    display: flex;
    align-items: center;
    .username {
      margin-left: 5px;
    }
  }
}
.descr-text,
addition {
  padding-right: 0.75rem;
}
.chart-graphic {
  margin-top: 5px;
  transform: translateX(-25px);
}
</style>
