<template lang="pug">
  .input-field
    input(
      :value="filePath",
      placeholder="C:\\",
      :id="id"
      type="text"
      class="validate",
      disabled,
    )
    .file-choose(
      @click="chooseFile"
    )
      FileSvg
    input(
      type="file",
      ref="file"
    )
    label(:for="id") {{label}}
</template>

<script>
import FileSvg from "@/components/icons/FileSvg";
export default {
  name: "FileInput",
  components: { FileSvg },
  data() {
    return {
      filePath: "C:\\",
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    chooseFile() {
      this.$refs.file.click();
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
.file-choose {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 18px;
}
label {
  color: #7cb858;
}
</style>
