<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0025 14.85C9.95277 14.85 8.94604 14.4444 8.20377 13.7224C7.4615 13.0003 7.04449 12.0211 7.04449 11C7.04449 9.97892 7.4615 8.99965 8.20377 8.27764C8.94604 7.55562 9.95277 7.15 11.0025 7.15C12.0522 7.15 13.059 7.55562 13.8012 8.27764C14.5435 8.99965 14.9605 9.97892 14.9605 11C14.9605 12.0211 14.5435 13.0003 13.8012 13.7224C13.059 14.4444 12.0522 14.85 11.0025 14.85ZM19.4048 12.067C19.45 11.715 19.4839 11.363 19.4839 11C19.4839 10.637 19.45 10.274 19.4048 9.9L21.7909 8.107C22.0058 7.942 22.0623 7.645 21.9266 7.403L19.6649 3.597C19.5292 3.355 19.2238 3.256 18.975 3.355L16.1592 4.455C15.5712 4.026 14.9605 3.652 14.2481 3.377L13.8296 0.462C13.7844 0.198 13.5469 0 13.2642 0H8.74078C8.45807 0 8.22059 0.198 8.17535 0.462L7.75693 3.377C7.04449 3.652 6.43383 4.026 5.84578 4.455L3.02994 3.355C2.78116 3.256 2.47582 3.355 2.34012 3.597L0.0784042 7.403C-0.0686074 7.645 -0.000755757 7.942 0.214107 8.107L2.60022 9.9C2.55498 10.274 2.52106 10.637 2.52106 11C2.52106 11.363 2.55498 11.715 2.60022 12.067L0.214107 13.893C-0.000755757 14.058 -0.0686074 14.355 0.0784042 14.597L2.34012 18.403C2.47582 18.645 2.78116 18.733 3.02994 18.645L5.84578 17.534C6.43383 17.974 7.04449 18.348 7.75693 18.623L8.17535 21.538C8.22059 21.802 8.45807 22 8.74078 22H13.2642C13.5469 22 13.7844 21.802 13.8296 21.538L14.2481 18.623C14.9605 18.337 15.5712 17.974 16.1592 17.534L18.975 18.645C19.2238 18.733 19.5292 18.645 19.6649 18.403L21.9266 14.597C22.0623 14.355 22.0058 14.058 21.7909 13.893L19.4048 12.067Z" fill="#7CB858"/>
  </svg>
</template>

<script>
  export default {
    name: "OptionsSvg"
  }
</script>

<style scoped>

</style>