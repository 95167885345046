<template lang="pug">
  .row
    .col.s6
      .input-field
        input(
          ref="input-name"
          placeholder="Имя сервера"
          id="server_name"
          type="text"
          class="validate"
          v-model="serverName"
        )
        .clear(
          @click="clearInput"
        )
          img(src="@/assets/img/input-clear.png")
        label(for="server_name") Поиск
    .col.s3
      .input-field
        select(id="server_type")
          option(value="kek") kek
          option(value="nekek") nekek
        label Тип сервера
    .col.s3
      .input-field
        select(id="server_version")
          option(value="kek") kek
          option(value="nekek") nekek
          option(value="kek") kek
          option(value="nekeke") nekek
          option(value="kekeer") kek
          option(value="nekekff") nekek
        label Версия сервера
</template>

<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";

export default {
  name: "ServerSelectForm",
  data() {
    return {
      serverName: "",
    };
  },
  computed: {
    filteredServerName() {
      return this.serverName.replace(/\s+/g, " ").trim();
    },
  },
  methods: {
    ...mapActions("Platforms", ["changeNameFilter"]),
    clearInput() {
      this.serverName = "";
    },
    // filterServersByName() {
    //   _.debounce(this.changeNameFilter(this.$refs.input - name.value), 150);
    // },
  },
  watch: {
    filteredServerName: debounce(function (val) {
      this.changeNameFilter(val);
    }),
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-top: 20px;
  margin-bottom: 0px;
}
.clear {
  cursor: pointer;
  position: absolute;
  top: 1em;
  right: 7px;
}
.input-field {
  margin-bottom: 0;
}
</style>

<style lang="scss">
.select-wrapper input.select-dropdown {
  color: rgba(238, 238, 238, 0.87);
}
.select-wrapper .caret {
  fill: rgba(238, 238, 238, 0.87);
}
.input-field {
  & > label {
    color: rgba(238, 238, 238, 0.87);
  }
  & > input {
    color: rgba(238, 238, 238, 0.87);
  }
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
  border-bottom: 2px solid rgba(238, 238, 238, 0.87);
}
input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom-width: 2px;
}

.select-dropdown.dropdown-content li {
  background-color: #34363b;
}
.select-dropdown.dropdown-content li:hover {
  background-color: lighten(#2b2c31, 10%);
}
.select-dropdown.dropdown-content li.selected {
  background-color: #2b2c31;
}
.dropdown-content li > a,
.dropdown-content li > span {
  color: rgba(238, 238, 238, 0.87);
}
.dropdown-content li.selected > a,
.dropdown-content li.selected > span {
  color: #7cb858;
}
</style>
