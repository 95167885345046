<template lang="pug">
  .servers
    ul
      li(
        v-for="item in getAllServers"
        :class="{active: item === getActiveServer}"
      )
        ServerCard(
          @click.native="setActiveItem(item)"
          :_id="item._id"
          :name="item.name"
          :onlineLimit="item.onlineLimit"
          :onlineCurrent="item.onlineCurrent",
          :isForge="item.isForge",
          :isOnline="item.isOnline",
          :minecraftVersion="item.minecraftVersion",
          :serverAddress="item.serverAddress",
          :previewImageUrl="item.previewImageUrl",
          :coverImageUrl="item.coverImageUrl",
          :description="item.description",
          :updatedAt="item.updatedAt"
          :tags="item.tags"
          :modList="item.modList",
          :playerList="item.playerList"
        )

</template>

<script>
import ServerCard from "@/components/ServerCard";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AppServers",
  components: { ServerCard },
  computed: {
    ...mapGetters("Platforms", ["getAllServers", "getActiveServer"]),
  },
  methods: {
    ...mapActions("Platforms", ["changeActiveServer"]),
    setActiveItem(item) {
      this.changeActiveServer(item._id);
    },
  },
};
</script>

<style lang="scss" scoped>
.servers {
  padding-right: 25px;
  max-height: calc(100vh - 190px);
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background-color: transparent;

    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #757575;
  }
  li {
    margin: 8px 0;
    transition: 0.15s ease-in;
    cursor: pointer;
  }
  li:hover {
    transform: translateX(22px);
  }
  li.active {
    margin: 16px 0;
    transform: translateX(22px);
  }
  li:first-child {
    margin-top: 0;
  }
}
</style>
