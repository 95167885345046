<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.22222 0H20.7778C21.1019 0 21.4128 0.105357 21.642 0.292893C21.8712 0.48043 22 0.734784 22 1V5C22 5.26522 21.8712 5.51957 21.642 5.70711C21.4128 5.89464 21.1019 6 20.7778 6H1.22222C0.898069 6 0.587192 5.89464 0.357981 5.70711C0.128769 5.51957 0 5.26522 0 5V1C0 0.734784 0.128769 0.48043 0.357981 0.292893C0.587192 0.105357 0.898069 0 1.22222 0ZM1.22222 8H20.7778C21.1019 8 21.4128 8.10536 21.642 8.29289C21.8712 8.48043 22 8.73478 22 9V13C22 13.2652 21.8712 13.5196 21.642 13.7071C21.4128 13.8946 21.1019 14 20.7778 14H1.22222C0.898069 14 0.587192 13.8946 0.357981 13.7071C0.128769 13.5196 0 13.2652 0 13V9C0 8.73478 0.128769 8.48043 0.357981 8.29289C0.587192 8.10536 0.898069 8 1.22222 8ZM1.22222 16H20.7778C21.1019 16 21.4128 16.1054 21.642 16.2929C21.8712 16.4804 22 16.7348 22 17V21C22 21.2652 21.8712 21.5196 21.642 21.7071C21.4128 21.8946 21.1019 22 20.7778 22H1.22222C0.898069 22 0.587192 21.8946 0.357981 21.7071C0.128769 21.5196 0 21.2652 0 21V17C0 16.7348 0.128769 16.4804 0.357981 16.2929C0.587192 16.1054 0.898069 16 1.22222 16ZM7.33333 4H8.55556V2H7.33333V4ZM7.33333 12H8.55556V10H7.33333V12ZM7.33333 20H8.55556V18H7.33333V20ZM2.44444 2V4H4.88889V2H2.44444ZM2.44444 10V12H4.88889V10H2.44444ZM2.44444 18V20H4.88889V18H2.44444Z" fill="#7CB858"/>
  </svg>
</template>

<script>
  export default {
    name: "ServersSvg"
  }
</script>

<style scoped>

</style>